import React from 'react'

import photoLaurence from '../../assets/photos/photo_laurence_bleue.png'
import LinkedInLogo from '../../assets/icones/reseaux/linkedin.png'
import FacebookLogo from '../../assets/icones/reseaux/facebook.png'

import '../../styles/components/about/Section1.css'

function Section1() {
  return (
    <div className='AboutSection1'>
      <div className='profile'>
        <div className='profileCard'>
          <img src={photoLaurence} alt="L'écrivain" id='profilePicture' />

          <h4>Laurence Moureux</h4>

          <div className='socialsLinksCard'>
            <a href='https://fr-fr.facebook.com/'>
              <img src={FacebookLogo} alt="Facebook" className='logoReseaux' />
            </a>

            <a href='https://www.linkedin.com/in/laurence-lm-vos-mots-26754b262/'>
              <img src={LinkedInLogo} alt="LinkedIn" className='logoReseaux' />
            </a>
          </div>
        </div>
      </div>

      <div className='profileDescriptionContainer'>
        <div className='profileDescription'>
          <h4>Diplômée de différentes universités</h4>

          <p>Master 2 : Droit sanitaire et social, université d'ASSAS</p>

          <p><a href="https://dauphine.psl.eu/formations/masters/economie-et-gestion-de-la-sante/m2-economie-et-gestion-des-organisations-sanitaires-et-medico-sociales">Master 2 : Management et gestion des organisations médico-sociales, université Paris-Dauphine</a></p>

          <p><a href="https://formations-lettres.sorbonne-universite.fr/fr/index/licence-XA/arts-lettres-langues-ALL/licence-lettres-lettres-modernes-LLETT1L_603.html">DEUG & Licence de lettres modernes, université Paris La Sorbonne</a></p>

          <p><a href="https://fondation-itsrs.org/educateur-trice-specialise-e.html">Diplôme d'état d'éducatrice spécialisée</a> et DEUG D'AES (Administration économique et sociale), université de Villetaneuse</p>
        </div>
      </div>
    </div>
  )
}

export default Section1
