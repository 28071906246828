import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import Header from './components/Header';
import Footer from './components/Footer';

import Home from './pages/Home';
import Contact from './pages/Contact';
import Blog from './pages/Blog';
import About from './pages/About';
import Services from './pages/Services';

// Stylesheets
import './styles/App.css';
import './styles/components/Navbar.css';
import './styles/components/Header.css';
import './styles/components/Footer.css';

// fonts
import "./fonts/DancingScript/static/DancingScript-Medium.ttf";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <header>
          <Navbar />
          <div className='underNavbar'></div>
          <Header />
        </header>

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prestations" element={<Services />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/a-propos" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<h1>404 Not Found</h1>} />
          </Routes>
        </main>

        <footer>
          <Footer />
        </footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
