import React from 'react'

import Articles from '../components/blog/Articles'
import Form from '../components/Form'

function Blog() {
  return (
    <div className='Blog'>
			<h2>Nos derniers articles</h2>

			<Articles />

      <Form />
    </div>
  )
}

export default Blog
