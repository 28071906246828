import React from 'react'

import TarifsCorrection from './TarifsCorrection'
import TarifsRedaction from './TarifsRedaction'

import '../../styles/components/services/Section1.css'

function Section1() {
  return (
    <div className='ServicesSection1'>
      <div className='tarifs'>
        <h3>Corrections</h3>
        
        <TarifsCorrection />

        <h3>Rédaction</h3>

        <TarifsRedaction />
      </div>

      <p>Pour toute autre demande, sur devis.</p>
    </div>
  )
}

export default Section1
