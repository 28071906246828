import React from 'react'

import redaction3 from '../../assets/photos/redaction3.jpg'

import '../../styles/components/home/Section3.css'

function Section3() {
  return (
    <div className='Section3Container'>
        <div className='Section3'>
            <div className='imagesContainerSection3'>
                <img src={redaction3} alt="machine_a_ecrire_vintage" />
            </div>

            <div className='textContainerSection3'>
                <h3>Qui vous corrige?</h3>

                <p>En poste de direction dans le secteur médico-social depuis de nombreuses années, de formation de base littéraire complétée par un diplôme d'éducatrice spécialisée, Laurence oeuvre dans un respect total de vos demandes. </p>

                <div className='buttonContainerSection3'>
                    <a href="/a-propos">En savoir plus</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section3
