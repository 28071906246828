import React from 'react'
import Section1 from '../components/home/Section1'
import Section2 from '../components/home/Section2'
import Section3 from '../components/home/Section3'
import Form from '../components/Form'

function Home() {
  return (
    <div className='home'>
      <Section1 />

      <Section2 />

      <Section3 />

      <Form />
    </div>
  )
}

export default Home
