import React from 'react'
import '../../styles/components/headers/ContactHeader.css'

function ContactHeader() {
  return (
    <div className='imageContactHeaderContainer'>
      <div className='ContactHeaderContainer'>
        <div className='ContactHeader'>
            <h1>Contactez-Moi</h1>

            <div className='separatorContainer'>
              <div className='separator'></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ContactHeader
