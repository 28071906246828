import React from 'react'
import { Route, Routes } from 'react-router-dom';
import HomeHeader from './headers/HomeHeader';
import PrestationsHeader from './headers/PrestationsHeader';
import BlogHeader from './headers/BlogHeader';
import AboutHeader from './headers/AboutHeader'
import ContactHeader from './headers/ContactHeader';

function Header() {
  return (
    <>
      <Routes>
        <Route path='/' element={<HomeHeader />} />
        <Route path='/prestations' element={<PrestationsHeader />} />
        <Route path='/blog' element={<BlogHeader />} />
        <Route path='/a-propos' element={<AboutHeader />} />
        <Route path='/contact' element={<ContactHeader />} />
      </Routes>
    </>
  )
}

export default Header
