import React from 'react'

import image from '../../assets/photos/encre_plume.jpg'

import '../../styles/components/home/Section2.css'

function Section2() {
  return (
    <div className='Section2'>
        <div className='section2ImgContainer'>
            <div className='section2Img'>
                <img src={image} alt="Encre et plume" />
            </div>
        </div>

        <div className='section2ChiffresContainer'>
            <div className='section2Chiffres'>
                <h2>Quelques nombres au sujet<br/>de l'activité de l'entreprise</h2>

                <p>
                    L'entreprise a vu le jour en 2022, faisant suite à de nombreuses années d'activité en bénévolat auprès de publics variés
                    <br/>
                    (étudiants, salariés du secteur médico-social, cercle familial, centres de formation, etc...)
                </p>

                <div className='statsContainer'>
                    <div className='stats'>
                        <h3>10</h3>
                        <p>Mémoires</p>
                    </div>

                    <div className='stats'>
                        <h3>2</h3>
                        <p>Ateliers d'écriture</p>
                    </div>

                    <div className='stats'>
                        <h3>15</h3>
                        <p>CV</p>
                    </div>

                    <div className='statsLast'>
                        <h3>20</h3>
                        <p>Documents administratifs</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Section2
