import React from 'react'

import '../../styles/components/headers/PrestationsHeader.css'

function PrestationsHeader() {
  return (
    <div className='imagePrestationsHeaderContainer'>
      <div className='PrestationsHeaderContainer'>
        <div className='PrestationsHeader'>
            <h1>Nos Prestations</h1>

            <div className='separatorContainer'>
              <div className='separator'></div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default PrestationsHeader
