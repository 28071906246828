import React from 'react'
import '../../styles/components/contact/Section1.css'

function Section1() {
  return (
    <div className='ContactSection1'>
        <div className='addressItemContainer'>
            <div className='addressItem'>
                <h2>Gandelu</h2>

                <p className='address'>02810 (Aisne), France</p>

                <p className='phone'>+33 6 74 70 31 74</p>

                <p className='email'>
                    <a href="mailto:lmvosmots@gmail.com">lmvosmots@gmail.com</a>
                </p>
            </div>

            {/* <div className='addressItem2'>
                <p>Horaires</p>

                <p>Château-Thierry, France</p>

                <p>Distanciel</p>
            </div> */}
        </div>

        <div className='ContactSection1Description'>
          <p>J’interviens sur le sud du département de l’Aisne et dans le Nord du département de Seine et Marne,
            sans frais de déplacements. Pour tout dépassement au-delà d’un rayon de 20 kilomètres, j’applique
            des frais kilométriques calculés selon le barème fiscal de l’année en cours.</p>
        </div>
    </div>
  )
}

export default Section1
