import React from 'react'

import '../../styles/components/services/TarifsRedaction.css';

function TarifsRedaction() {
  return (
    <div className='TarifsRedaction'>
      <table>
        <thead>
          <tr>
            <th>Type de documents</th>
            <th>Prix de la prestation</th>
            {/* <th></th> */}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td data-title='Provider Name'>Courrier administratif</td>

            <td data-title='E-mail'>25€</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Forfait CV + lettre de motivation</td>

            <td data-title='E-mail'>60€</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Courrier personnel</td>

            <td data-title='E-mail'>à partir de 30€ la page</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Discours, hommages…</td>

            <td data-title='E-mail'>sur devis (base 50€/ heure de travail)</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Courriers professionnels</td>

            <td data-title='E-mail'>sur devis</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Ateliers d’écriture</td>

            <td data-title='E-mail'>tarifs selon devis sur la base de 50€/heure</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th colspan='3'>Confidentialité assurée</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default TarifsRedaction
