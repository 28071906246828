import React from 'react'

import '../../styles/components/headers/BlogHeader.css'

function BlogHeader() {
  return (
    <div className='imageBlogHeaderContainer'>
    <div className='BlogHeaderContainer'>
      <div className='BlogHeader'>
          <h1>Blog</h1>

          <div className='separatorContainer'>
            <div className='separator'></div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default BlogHeader
