import React from 'react'
import '../styles/components/Form.css'

function Form(){
    return (
        <div className='form'>
            <div className='formTitlesContainer'>
                <div className='formTitles'>
                    <h3>Parlez-moi de votre projet,
                        <br/>
                        <span>Contactez-moi !</span>
                    </h3>
                </div>

                <div className='paragraph'>
                    <p>Je réponds à votre demande par mail sous 48h</p>
                </div>
            </div>

            <div className='formInputsContainer'>
                <div className='formInputs'>
                    <form action="https://formsubmit.co/lmvosmots@gmail.com" method='POST'>
                        <p>
                            <label htmlFor="firstName">Nom</label>
                            <input type="text" name='Nom' id='firstName' placeholder='Nom' />
                        </p>

                        <p>
                            <label htmlFor="email">Email</label>
                            <input type="email" name="Email" id="email" placeholder='Email' />
                        </p>

                        <p>
                            <label htmlFor="phone">Téléphone</label>
                            <input type="tel" name='Téléphone' id='phone' placeholder='Téléphone' />
                        </p>

                        <p className='textArea'>
                            <label htmlFor="message">Message</label>
                            <textarea name="Message" placeholder='Message'></textarea>
                        </p>

                        <div className='formButtonContainer'>
                            <button>Envoyer</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Form;
