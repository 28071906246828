import React from 'react'

import logoLinkedin from "../assets/icones/reseaux/linkedin.png"
import logoFacebook from "../assets/icones/reseaux/facebook.png"
import logoViber from "../assets/icones/reseaux/viber.png"

import '../styles/components/Footer.css'

function Footer() {
  return (
    <div className='footerContainer'>
      <div className='footer'>
        <p>Laurence Moureux © 2023 | By <a href="https://portfolio-marcus.vercel.app/" target="blank">Step</a></p>

        <div className='footerRight'>
          {/* <p><a href="/mentions-legales">Mentions Légales</a></p> */}

          <ul>
            <li>
              <a href='https://www.linkedin.com/in/laurence-moureux-659889115/'>
                <img src={logoLinkedin} alt="linkedin" />
              </a>
            </li>

            <li>
              <a href='https://fr-fr.facebook.com/'>
                <img src={logoFacebook} alt="facebook" />
              </a>
            </li>

            <li>
              <a href='tel:+33762312345'>
                <img src={logoViber} alt="appel" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
