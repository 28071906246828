import React from 'react'

import Section1 from '../components/services/Section1'

import '../styles/pages/Services.css'

function Services() {
  return (
    <div className="services">
      <Section1 />
    </div>
  )
}

export default Services
