import React from 'react'

import iconeChat from '../../assets/icones/section1/bulle-de-chat.png'
import iconeCI from '../../assets/icones/section1/carte-didentite.png'
import iconeEcrire from '../../assets/icones/section1/ecrire.png'
import iconeEmail from '../../assets/icones/section1/email.png'
import iconeNavigateur from '../../assets/icones/section1/navigateur.png'
import iconeRemarque from '../../assets/icones/section1/remarque.png'

import '../../styles/components/home/Section1.css'

function Section1() {
  return (
    <div className='section1Container'>
				<div className='section1'>
					<div className='titleContainer'>
						<h2>Le rôle de l'écrivain public</h2>
						
						<p>Un écrivain public est un professionnel qui aide les particuliers et les entreprises à rédiger des documents tels que des lettres, des CV, des discours, des articles, des thèses, des mémoires, etc. Il peut aider à améliorer la qualité de ces documents en corrigeant la grammaire, l'orthographe, la syntaxe et la structure. Il peut également aider à adapter les documents aux besoins et aux attentes des destinataires.</p>
					</div>

                    <div className='itemsContainer itemsContainer1'>
                        <a href="/prestations">
                            <div className='items'>
                                <div className='item'>
                                    <div className='imgContainer'>
                                        <img src={iconeChat} alt='icone chat' />
                                    </div>
                                    
                                    <h3>Atelier d'écriture</h3>
                                    
                                    <p>Travail en collaboration / Développement de la créativité pour vos écrits</p>
                                </div>
                            
                                <div className='item'>
                                    <div className='imgContainer'>
                                        <img src={iconeCI} alt='icone carte identité' />
                                    </div>
                                    
                                    <h3>Pack Etudiant</h3>
                                    
                                    <p>Correction Mémoires / Rapports de stages / Dissertations</p>
                                </div>

                                <div className='item itemLast'>
                                    <div className='imgContainer'>  
                                        <img src={iconeNavigateur} alt='icone navigateur' />
                                    </div>

                                    <h3>Biographie / Récit de vie</h3>

                                    <p>Ecoute, rédaction et mise en forme de vos récits</p>
                                </div>
                            </div>
                        </a>
                    </div>

                    <div className='itemsContainer itemsContainer2'>
                        <a href="/prestations">
                            <div className='items'>
                                <div className='item'>
                                    <div className='imgContainer'>
                                        <img src={iconeRemarque} alt='icone remarque' />
                                    </div>
                                    
                                    <h3>Lettres de Motivation / CV</h3>
                                    
                                    <p>Correction et / ou rédaction de votre lettre de motivation et / ou de votre CV</p>
                                </div>

                                <div className='item'>
                                    <div className='imgContainer'>
                                        <img src={iconeEmail} alt='icone email' />
                                    </div>

                                    <h3>Documents Administratifs / Emails</h3>

                                    <p>Rédaction / Correction de vos emails et courriers professionnels et personnels</p>
                                </div>

                                <div className='item itemLast'>
                                    <div className='imgContainer'>
                                        <img src={iconeEcrire} alt='icone ecrire' />
                                    </div>

                                    <h3>Discours</h3>

                                    <p>Correction / Rédaction de vos discours :<br/>Assemblée générale, Voeux, Mariages, Eloges funèbres...</p>
                                </div>
                            </div>
                        </a>
                    </div>
				</div>
			</div>
  )
}

export default Section1
