import React from 'react'
import '../../styles/components/headers/HomeHeader.css'

function HomeHeader() {
  return (
    <div className='homeHeader'>
		<div className='titlesContainer'>
			<div className='titles'>
				<p className='surtitle'>Services et conseils</p>

				<h1>LM Vos Mots<br/><span>Ecrivain public</span></h1>

				<p className='description'>LM vos mots est une entreprise de rédaction et de correction de vos documents personnels et professionnels, LM vos mots offre une gamme de services de qualité, qui peut aider les entreprises et les particuliers à améliorer leur communication écrite.</p>

				<div className='buttonContainer'>
					<a href='/contact'>Contactez Nous</a>
				</div>
			</div>
		</div>

		<div className='heroImage'></div>
	</div>
  )
}

export default HomeHeader
