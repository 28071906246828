import React from 'react'

import '../../styles/components/headers/AboutHeader.css'

function AboutHeader() {
  return (
    <div className='imageAboutHeaderContainer'>
    <div className='AboutHeaderContainer'>
      <div className='AboutHeader'>
          <h1>à Propos</h1>

          <div className='separatorContainer'>
            <div className='separator'></div>
          </div>
      </div>
    </div>
  </div>
  )
}

export default AboutHeader
