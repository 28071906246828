import React, { useState } from 'react';
import '../styles/components/Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const pages = [
    { name: "Accueil", path: "/" },
    { name: "Prestations", path: "/prestations" },
    // { name: "Blog", path: "/blog"},
    { name: "À propos", path: "/a-propos" },
    { name: "Contact", path: "/contact" },
  ];

  return (
    <nav>
      <div className="nav-container">
        <div className="logo">
          <a href="/">
            <img src={require('../assets/logo/noir/logo-removebg.png')} alt="Logo de l'entreprise" />

            <p>LM Vos Mots</p>
          </a>
        </div>

        <div className={`menu${isOpen ? ' menuOpen' : ''}`}>
          { pages.map(page => (
            <a href={page.path} key={page.name}>{page.name}</a>
          )) }
        </div>

        <div className='burgerContainer'>
          <div className={`burgerBtn${isOpen ? ' burgerBtnOpen' : ''}`} onClick={() => setIsOpen(!isOpen)}>
            <div className="line1"></div>
            <div className="line2"></div>
            <div className="line3"></div>
          </div>
        </div>
      </div>          
    </nav>
  );
}

export default Navbar;
