import React from 'react'

import couvertureMagazine from '../../assets/lire_magazine/couverture_magazine.jpg'
import articleMagazine from '../../assets/lire_magazine/article_magazine.jpg'

import '../../styles/components/about/Section2.css'

function Section2() {
  return (
    <div className='aboutSection2'>
        <div className="magazineDescription">
            <p>Organisation d'un concours de nouvelles ouvert à tous</p>
        </div>

        <div className="magazine">
            <img src={couvertureMagazine} alt="Couverture Magazine" className='couvertureMagazine' />

            <img src={articleMagazine} alt="Article Magazine" className='articleMagazine' />
        </div>
    </div>
  )
}

export default Section2
