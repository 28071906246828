import React from 'react'

import Section1 from '../components/about/Section1'
import Section2 from '../components/about/Section2'
import Form from '../components/Form'

import '../styles/pages/About.css'

function About() {
  return (
    <div className='AboutPage'>
      <Section1 />

      <div className='divspan'>
        <span></span>
      </div>

      <Section2 />

      <Form />
    </div>
  )
}

export default About
