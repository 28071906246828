import React from 'react'

import Section1 from '../components/contact/Section1'
import MyMapComponent from '../components/contact/MyMapComponent'
import Form from '../components/Form'

import '../styles/pages/Contact.css'

function Contact() {
  return (
    <>
      <div className='ContactContainer'>
        <div className='Contact'>
          <Section1 />
        </div>

        <MyMapComponent />
      </div>

      <Form />
    </>
  )
}

export default Contact
