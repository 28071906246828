import React from 'react'

import '../../styles/components/services/TarifsCorrection.css';

function TarifsCorrection() {
  return (
    <div className='TarifsCorrection'>
      <table>
        <thead>
          <tr>
            <th>Type de documents</th>
            <th>Prix de la prestation</th>
            {/* <th></th> */}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td data-title='Provider Name'>Rapport de stage, mémoire…</td>

            <td data-title='E-mail'>à partir de 2€ la page</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Comptes rendus de réunions</td>

            <td data-title='E-mail'>2€ la page</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>

          <tr>
            <td data-title='Provider Name'>Manuscrits : biographie, récits de vie, VAE… </td>

            <td data-title='E-mail'>à partir de 2€ la page</td>

            {/* <td class='details'>
              <a class='button' href='#'>Voir en détails</a>
            </td> */}
          </tr>
        </tbody>

        <tfoot>
          <tr>
            <th colspan='3'>Confidentialité assurée</th>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default TarifsCorrection
